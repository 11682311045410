var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wizard-container row" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.saveBrandCompetition($event)
          }
        }
      },
      [
        _c("div", { staticClass: "col-sm-5" }, [
          _c("h2", [_vm._v("Save Brand Competitors")]),
          _c(
            "ul",
            _vm._l(_vm.uniqueCompetitions, function(competition, index) {
              return _c("li", { key: index }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedCompetitors,
                      expression: "selectedCompetitors"
                    }
                  ],
                  attrs: { type: "checkbox", id: "comp" + index },
                  domProps: {
                    checked: true,
                    value: index,
                    checked: Array.isArray(_vm.selectedCompetitors)
                      ? _vm._i(_vm.selectedCompetitors, index) > -1
                      : _vm.selectedCompetitors
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.selectedCompetitors,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = index,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.selectedCompetitors = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.selectedCompetitors = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.selectedCompetitors = $$c
                      }
                    }
                  }
                }),
                _c("label", { attrs: { for: "comp" + index } }, [
                  _vm._v(
                    _vm._s(competition.cls) +
                      " (Confidence: " +
                      _vm._s(competition.conf) +
                      ")"
                  )
                ])
              ])
            }),
            0
          )
        ]),
        _c("div", { staticClass: "col-sm-7" }, [
          _c("h2", [_vm._v("Task Image")]),
          _c("div", { staticClass: "canvas-wrapper" }, [
            _c("img", {
              staticClass: "img-responsive",
              attrs: { src: _vm.selectedTaskImage }
            }),
            _c("canvas", { ref: "myCanvas", staticClass: "canvas-overlay" })
          ]),
          _c("br"),
          _c("br"),
          _c(
            "button",
            {
              staticClass: "btn-lg-green col-sm-3 pull-right",
              attrs: { type: "submit", disabled: _vm.busy }
            },
            [_vm._v("SAVE NOW")]
          ),
          _c("br"),
          _c("br")
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }