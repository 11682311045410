<template>
  <div class="wizard-container row">
    <form v-on:submit.prevent="saveBrandCompetition">
      <div class="col-sm-5">
        <h2>Save Brand Competitors</h2>
        <ul>
          <li v-for="(competition, index) in uniqueCompetitions " :key="index">
            <input type="checkbox" :id="'comp' + index" :checked="true" :value="index" v-model="selectedCompetitors">
            <label :for="'comp' +  index">{{ competition.cls }} (Confidence: {{competition.conf}})</label>
          </li>
        </ul>
      </div>
      <div class="col-sm-7">
        <h2>Task Image</h2>
        <!-- <img :src="selectedTaskImage" class="img-responsive" /> -->
        <div class="canvas-wrapper">
        <img :src="selectedTaskImage" class="img-responsive"/>
        <canvas ref="myCanvas" class="canvas-overlay"></canvas>
    </div>
        <br/><br/>
        <button class="btn-lg-green col-sm-3 pull-right" type="submit" :disabled="busy">SAVE NOW</button><br/><br/>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  data () {
    return {
      competitions: [],
      selectedCompetitors: [],
      busy: false,
      pickl: {},
      selectedTaskImage: '',
      updatedCompetitions: []
    }
  },
  name: 'BrandPicklCompetitions',
  computed: {
    uniqueCompetitions () {
      return this.competitions.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.cls]: current
        })
      }, {})
    },
    taskImage () {
      let taskImageArray = this.selectedTaskImage.split('/')
      return taskImageArray[taskImageArray.length - 1]
    }
  },
  methods: {
    getBrandPicklCompetitions () {
      this.$store.dispatch('getBrandPicklCompetitions', { userPicklId: this.pickl.user_pickl.id, image: this.taskImage }).then((response) => {
        this.competitions = response.body
        let c = this.$refs['myCanvas']
        const canvas = c.getContext('2d')
        canvas.beginPath()
        this.competitions.forEach(elem => {
          canvas.rect(elem.bbox[0], elem.bbox[0], elem.bbox[0], elem.bbox[0])
          canvas.rect(0, 0, 299, 150)
        })
        canvas.strokeStyle = 'yellow'
        canvas.stroke()
      }, (response) => {
        this.busy = false
      })
    },
    saveBrandCompetition () {
      if (this.selectedCompetitors.length === 0) {
        this.$notify({ type: 'error', text: 'Please select atleast one competitor brand to save!' })
        return false
      }

      let competitions = []
      this.competitions.forEach((elem) => {
        competitions.push({ nearbyBrandName: elem.cls, confidenceLevel: elem.conf })
      })

      let data = {
        picklId: parseInt(this.$route.params.picklId),
        taskId: parseInt(this.$route.params.taskId),
        userId: parseInt(this.pickl.user_pickl.user_id),
        brandId: parseInt(this.pickl.brand_id),
        approvedUserId: this.$store.state.userData.id,
        competitions: competitions
      }
      this.$store.dispatch('saveBrandCompetitions', data).then((response) => {
        this.competitions = response.response.body
      }, (response) => {
        this.busy = false
      })
    },
    getPicklDetails () {
      this.$store.dispatch('getPicklDetails', { picklId: this.$route.params.picklId }).then((response) => {
        this.pickl = response.response.body
        if (this.pickl.user_pickl.user_pickl_photos) {
          this.pickl.user_pickl.user_pickl_photos.forEach(elem => {
            if (elem.task_id === parseInt(this.$route.params.taskId)) {
              this.selectedTaskImage = elem.image
              this.getBrandPicklCompetitions()
            }
          })
        }
      })
    }
  },
  mounted () {
    this.getPicklDetails()
  }
}
</script>
<style scoped>
.wizard-container {
  width: 70%;
  margin: auto;
  border: solid 2px rgb(130, 222, 109);
  padding: 1%;
  border-radius: 20px;
}
select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url('/img/Create Pickl/selectproductDropdown_icon.png');
  background-color: white;
}
.answer-input {
  height: 30px;
  border-radius: 10px;
  margin: 0;
  width: 90%;
}
.add-more-ans {
  font-size: 15px;
  text-decoration: none;
  display: block;
  margin-left: 3%;
}
.single-quesion-box {
  border: solid 1px rgb(130, 222, 109);
  padding: 1%;
  border-radius: 20px;
  margin: 10px;
}
.add-question-button {
  border: solid 1px rgb(130, 222, 109);
  color: black;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  display: block;
  width: 20%;
  text-align: center;
  padding: 1%;
  border-radius: 10px;
}
a {
  color: #782b54;
}
.delete-question-link {
  font-size: 16px;
  margin-top: 40px;
}
.answer-container {
  margin-left: 2%;
  margin: 1%;
}
.remove-answer-button {
  background: #782b54;
  height: 35px;
  border: none;
  border-radius: 150px;
  color: white;
  padding: 3px 10px 0px 10px;
}
.localstorage-existing-data-notice {
  text-align: center;
  display: block;
  color: black;
  font-size: 16px;
  margin-bottom: 10px;
  transition: opacity cubic-bezier(0, 0.52, 1, 1) 1s;
}
.progress-bar-container {
  width: 60%;
  margin: auto;
}
@media only screen and (max-width: 1024px) {
  .wizard-container {
    width: 100%;
    margin: auto;
    border: solid 2px rgb(130, 222, 109);
    padding: 1%;
    border-radius: 20px;
  }
}
.canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  width: 100%;
  height: 100%
}

.canvas-wrapper {
  position: relative;
}
</style>
